/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import classNames from 'classnames';
import React, { useMemo, useState, useEffect } from 'react';
import Button from '../../components/Buttons/Button/Button.js';
import client from '../../services/client.js';
import useTournament from '../../contexts/TournamentsContext/TournamentsContext.js';

const getIntervalName = (playInfo) => {
  if (!playInfo) {
    return 'Game';
  }
  if (playInfo.num_of_rounds === 1) {
    // if num_of_rounds is 1, we respect play_interval_name and break it up into quarters, halfs etc.
    switch (playInfo.play_interval_name) {
      case 'Quarters':
        return 'Quarter';
      case 'Halfs':
        return 'Half';
      default:
        return 'Game';
    }
  }
  // if num_of_rounds is greater than 1, always use "game"
  return 'Game';
};

const FinalizeScore = ({ match, round, scores, television, onFinalize }) => {
  const { event } = useTournament();

  const team1UUID = useMemo(
    () =>
      television?.television_channel_participants.find((p) =>
        event.teams[0].roster.players.some(
          (player) => player.user_profile.id === p.user_profile.id,
        ),
      )?.group_identifier,
    [event.teams, television?.television_channel_participants],
  );

  const team2UUID = useMemo(
    () =>
      television?.television_channel_participants.find((p) =>
        event.teams[1].roster.players.some(
          (player) => player.user_profile.id === p.user_profile.id,
        ),
      )?.group_identifier,
    [event.teams, television?.television_channel_participants],
  );

  const potentialWinner = useMemo(() => {
    const { team1AllWins, team2AllWins } = (scores || []).reduce(
      (prev, curr) => {
        if (curr.team_uuid === team1UUID) {
          return {
            ...prev,
            team1AllWins: curr.won ? prev.team1AllWins + 1 : prev.team1AllWins,
          };
        }
        if (curr.team_uuid === team2UUID) {
          return {
            ...prev,
            team2AllWins: curr.won ? prev.team2AllWins + 1 : prev.team2AllWins,
          };
        }
        return prev;
      },
      { team1AllWins: 0, team2AllWins: 0 },
    );
    return (
      team1AllWins > event.play_info.num_of_rounds / 2 ||
      team2AllWins > event.play_info.num_of_rounds / 2
    );
  }, [event, scores, team1UUID, team2UUID]);

  const getDisabled = () => {
    if (!match?.id) return true;
    const scoresEqual = (scores || [])
      .filter((s) => s.round_number === round)
      .every((s, i, arr) => s.score === arr[0].score);

    return round === event.play_info.num_of_rounds && scoresEqual;
  };

  const handleFinalize = async () => {
    try {
      const payload = {
        round_number: round,
      };

      if (potentialWinner) {
        payload.final = true;
      }

      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${match.id}/play_by_plays/finalize_round`,
        payload,
      );
      onFinalize();
    } catch (e) {
      alert(e.response?.data?.message || e.message || 'Something went wrong');
    }
  };

  return (
    <Button
      text="Finalize Match"
      variant="grey"
      rounded
      large
      className="w-full !px-6"
      onClick={() => handleFinalize()}
      disabled={getDisabled()}
    />
  );
};

const NextInterval = ({ match }) => {
  const { event } = useTournament();
  const [error, setError] = useState('');

  // if event is not live, we don't show the next interval button
  if (event.state !== 'live') {
    return null;
  }

  // if game is round of 1, there's no next interval
  if (event.play_info.num_of_rounds === 1) {
    return null;
  }

  // if play_interval_name is null, we don't show the next interval button
  if (event.play_info.play_interval_name === null) {
    return null;
  }

  const handleIntervalChange = async () => {
    try {
      setError('');
      await client.post(
        `${process.env.REACT_APP_API_URL}//admin/v1/matches/${match.id}/play_by_plays/play_interval`,
        {},
      );
    } catch (e) {
      setError(
        e.response?.data?.message || e.message || 'Something went wrong',
      );
    }
  };

  const interval = getIntervalName(event?.play_info);

  return (
    <>
      <Button
        text={`Next ${interval}`}
        variant="primary"
        rounded
        large
        className="w-full !px-8"
        onClick={() => {
          const confirmed = window.confirm(
            `Are you sure you want to end this ${interval}?`,
          );
          if (confirmed) {
            handleIntervalChange();
          }
        }}
      />
      {error && <p className="text-red-500">{error}</p>}
    </>
  );
};

const ScoreCard = ({ match, television }) => {
  const {
    event,
    scores,
    tournament,
    fetchScores,
    fetchOptions,
    getTelevision,
    getMatch,
    getEvent,
  } = useTournament();
  const currentRound = scores?.length
    ? Math.max(...scores.map((s) => s.round_number))
    : null;

  if (event.teams.length === 1) {
    return null;
  }

  const handleFinalize = () => {
    fetchScores(event.match_event_match_id);
    fetchOptions(event.match_event_match_id);
    getTelevision(event.television?.id);
    getMatch(event.match_event_match_id);
    getEvent(tournament.id);
  };

  useEffect(() => {
    if (scores.length === 0 && event?.match_event_match_id) {
      fetchScores(event.match_event_match_id);
    }
  }, [event?.match_event_match_id]);

  const gameRounds = useMemo(() => {
    if (!event?.play_info) {
      return [];
    }
    if (event.play_info.num_of_rounds === 1) {
      // if num_of_rounds is 1, we respect play_interval_name and break it up into quarters, halfs etc.
      switch (event.play_info.play_interval_name) {
        case 'Quarters':
          return [
            { code: 'Q', name: 'Quarter', i: 1 },
            { code: 'Q', name: 'Quarter', i: 2 },
            { code: 'Q', name: 'Quarter', i: 3 },
            { code: 'Q', name: 'Quarter', i: 4 },
          ];
        case 'Halfs':
          return [
            { code: 'H', name: 'Half', i: 1 },
            { code: 'H', name: 'Half', i: 2 },
          ];
        default:
          return [{ code: 'M', name: 'Match', i: 1 }];
      }
    }
    // if num_of_rounds is greater than 1, we use match_format_text
    return Array.from(Array(event.play_info.num_of_rounds).keys()).map(
      (_, i) => ({
        code: event.play_info.match_format_text.slice(0, 1),
        name: event.play_info.match_format_text,
        i: i + 1,
      }),
    );
  }, [event.play_info]);

  const scoresTableData = useMemo(() => {
    if (!scores) return {};

    return event.teams.reduce((acc, curr) => {
      const firstPlayer = (curr.roster.players || []).sort((a, b) => {
        if (a.type === 'leader' && b.type !== 'leader') return -1;
        if (a.type !== 'leader' && b.type === 'leader') return 1;
        return a.id - b.id;
      })[0];

      return {
        ...acc,
        [curr.roster.team_id]: {
          scores: scores.filter((s) => s.team_id === curr.roster.team_id),
          jerseyColor: curr.jersey_color,
          teamImg: firstPlayer?.user_profile.profile_image_url,
          teamId: curr.roster.team_id,
          code: curr.roster.code,
        },
      };
    }, {});
  }, [event.teams, scores]);

  const totalScores = useMemo(() => {
    if (scores.length > 0 && ['completed', 'review'].includes(event.state)) {
      return scores.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.team_id]: (acc[curr.team_id] ?? 0) + curr.score,
        };
      }, {});
    }
    return {};
  }, [event.state, scores]);

  // className={cn('mb-4 text-6xl font-extrabold text-white', {
  //   '!text-gold': isWinner,
  // })}

  const winningTeam = (event?.teams ?? []).find((t) => t.placement === 1);

  return (
    <div className="flex flex-col gap-4 border border-grey-3 p-4 text-white">
      <div className="flex">
        <div className="flex w-full flex-col">
          <div className="flex w-full items-center gap-2 overflow-hidden !border-b-grey-3">
            <div className="flex min-w-[80px] items-center gap-2 p-2" />
            {gameRounds.map((r) => (
              <div
                key={`round-${r.i}`}
                className="flex flex-1 justify-center font-bold"
              >
                <span className="text-white">
                  {r.code}
                  {r.i}
                </span>
              </div>
            ))}
            <div className="flex flex-1 justify-center border-l border-grey-3">
              <span className="text-white">
                {gameRounds.length > 3 ? 'T' : 'TOTAL'}
              </span>
            </div>
          </div>

          {Object.values(scoresTableData).map((t, tdx) => {
            return (
              <div
                key={`${t.teamId}-${t.code}`}
                className="flex w-full items-center gap-2 overflow-hidden !border-b-grey-3"
                style={{
                  borderTopLeftRadius: tdx === 0 ? '12px' : '',
                  borderBottomLeftRadius: tdx === 1 ? '12px' : '',
                  borderBottom: tdx === 0 ? '1px solid' : '',
                }}
              >
                <div
                  className="flex min-w-[80px] items-center gap-2 p-2"
                  style={{ backgroundColor: t.jerseyColor }}
                >
                  <img
                    src={t.teamImg}
                    alt="team"
                    className="h-[16px] w-[16px] rounded-full object-cover sm:h-[20px] sm:w-[20px]"
                  />
                  <h3>{t.code.toUpperCase()}</h3>
                </div>

                {gameRounds.map((round, sdx) => {
                  const s =
                    t?.scores?.find((v) => v.round_number === round.i) ?? {};
                  return (
                    <div
                      key={`${s.team_uuid}-${round.i}`}
                      className="flex flex-1 justify-center"
                    >
                      <span
                        className={classNames('text-white', {
                          '!text-gold': s.won && s.completed,
                          '!text-grey-2': !s.won && s.completed,
                        })}
                      >
                        {s?.score_display || '-'}
                      </span>
                    </div>
                  );
                })}

                <div className="flex flex-1 justify-center border-l border-grey-3">
                  <span
                    className={classNames('font-extrabold text-white', {
                      '!text-gold': winningTeam?.roster.team_id === t.teamId,
                    })}
                  >
                    {totalScores[t.teamId] || '-'}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col items-center gap-2">
        <NextInterval match={match} />
        <FinalizeScore
          match={match}
          scores={scores}
          television={television}
          round={currentRound}
          onFinalize={handleFinalize}
        />
      </div>
    </div>
  );
};

export default ScoreCard;
