/* eslint-disable react/jsx-no-bind */
import React from 'react';
import classNames from 'classnames';
import Countdown from 'react-countdown';

import useTournament from '../../contexts/TournamentsContext/TournamentsContext.js';
import Button from '../../components/Buttons/Button/Button.js';
import VortexCountdown from './VortexCountdown';
import StartGame from './StartGame';
import client from '../../services/client';

function EventStatus() {
  const {
    tournament,
    match,
    event,
    scores,
    fetchScores,
    getEvent,
    getMatch,
    getTelevision,
    getTournament,
    fetchOptions,
  } = useTournament();
  const currentRound = scores?.length
    ? Math.max(...scores.map((s) => s.round_number))
    : null;

  function handleStart() {
    fetchScores(event.match_event_match_id);
    fetchOptions(event.match_event_match_id);
    getTelevision(event.television?.id);
    getMatch(event.match_event_match_id);
    getEvent(tournament.id);
  }

  const handleMoveToLive = async () => {
    try {
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/events/${event.id}/replay`,
      );
      getTournament(tournament.id);
      getEvent(tournament.id);
    } catch (e) {
      console.log(
        e.response?.data?.message || e.message || 'Something went wrong',
      );
    }
  };

  const handlePausePayout = async () => {
    try {
      const res = window.confirm('Are you sure!?');
      if (res) {
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournament.id}/block_payout`,
        );
        getTournament(tournament.id);
        getEvent(tournament.id);
      }
    } catch (e) {
      console.log(
        e.response?.data?.message || e?.message || 'Something went wrong',
      );
    }
  };

  // check if we've finalized the round and we're live, we should proceed to the next "game"
  if (currentRound && event.state === 'live') {
    if (!scores.every((s) => s.completed)) {
      return null;
    }
  }

  // TODO: if last round, do not show start game button

  return (
    <div className="flex w-full flex-1 flex-col text-white">
      {event.state === 'pending' && (
        <VortexCountdown event={event} onComplete={handleStart} />
      )}
      {event.state === 'live' && (
        <StartGame
          round={currentRound + 1 || 1}
          match={{ id: event.match_event_match_id }}
          onStart={handleStart}
        />
      )}
      {event.state === 'review' && (
        <div className="mx-auto flex h-full w-full max-w-7xl flex-col items-center justify-center gap-4 px-4">
          <p className="text-center text-5xl font-[900] leading-none text-gold md:text-6xl 2xl:text-[100px]">
            <Countdown
              date={
                new Date(new Date(event.end_date).getTime() + 15 * 60 * 1000)
              }
              onComplete={() => {}}
              renderer={(cd) => (
                <>
                  {cd.formatted.minutes}:{cd.formatted.seconds}
                </>
              )}
            />
          </p>
          <h3 className="text-3xl font-extrabold">Match in Review</h3>
          <div className="flex gap-4">
            <Button
              text={tournament?.lock ? 'Payout Blocked' : 'Block Payouts'}
              variant="tertiary"
              disabled={tournament?.lock}
              rounded
              large
              className="whitespace-nowrap !px-6 disabled:opacity-50"
              onClick={handlePausePayout}
            />
            <Button
              text="Move Match to Live"
              variant="primary"
              rounded
              large
              className="w-full !px-6"
              onClick={handleMoveToLive}
            />
          </div>
        </div>
      )}
      {event.state === 'completed' && (
        <div className="mx-auto flex h-full w-full max-w-7xl flex-col items-center justify-center gap-4 px-4">
          <h3 className="text-3xl font-extrabold">Event Completed</h3>
          <p className="text-grey-2">You may still manually update scores</p>
        </div>
      )}
      {event.state === 'canceled' && (
        <div className="mx-auto flex h-full w-full max-w-7xl flex-col items-center justify-center gap-4 px-4">
          <h3 className="text-3xl font-extrabold">Event Cancelled</h3>
        </div>
      )}
    </div>
  );
}

export default EventStatus;
