/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import Layout from '../../components/Layout/Layout.js';
import Loader from '../../components/Loader/Loader.js';
import Button from '../../components/Buttons/Button/Button.js';
import SlideOut from '../../components/SlideOutContent/SlideOutContent.js';
import Modal from '../../components/BaseModal/BaseModal.js';
import ClipsList from '../../features/ClipsList/ClipsList.js';
import ChatFeed from '../../components/Chat/ChatFeed/ChatFeed.js';
import DisputeCard from '../../components/DisputeCard/DisputeCard.js';
import Bracket from '../../features/Brackets/Brackets.js';

import { formatBrackets } from '../../utils/formatBrackets.js';

import useAuth from '../../contexts/AuthContext/AuthContext.js';
import useTournament from '../../contexts/TournamentsContext/TournamentsContext.js';

import RulesImage from '../../assets/Icons/rules.svg';
import RulesWhiteImage from '../../assets/Icons/rules-white.svg';
import PrizesImage from '../../assets/Icons/prizes.svg';
import BracketsImage from '../../assets/Icons/bracket.svg';
import firstPlaceIcon from '../../assets/Icons/1-icon.svg';
import secondndPlaceIcon from '../../assets/Icons/2-icon.svg';
import thirdPlaceIcon from '../../assets/Icons/3-icon.svg';
import MatchCard from '../../features/Matches/MatchCard.tsx';
import client from '../../services/client.js';
import { Jersey } from './UpdateTournament.js';
import MatchEventManager from './MatchEventManagerV2.jsx';
import BracketV2 from '../../features/BracketV2.js';
import AnnouncementModal from './AnnoucementModal.jsx';

const TournamentsManager = () => {
  const {
    // loading,
    isMore,
    teams,
    tournament,
    event,
    getEvent,
    tournamentBracket,
    rules,
    gameMode,
    comments,
    clips,
    disputes,
    getTeams,
    getTournament,
    getTournamentBracket,
    initBracketSocket,
    getRulesSet,
    getGameMode,
    getComments,
    getMoreMessages,
    getClips,
    getDisputes,
    disqualifyTeam,
    deleteComment,
    silenceUser,
    submitMatchPlacement,
    matches,
    fetchMatches,
    setEvent,
  } = useTournament();
  const { tournamentId } = useParams();
  const { god } = useAuth();

  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const [isPrizesOpen, setIsPrizesOpen] = useState(false);
  const [isBracketOpen, setIsBracketOpen] = useState(false);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [openUserModal, setUserModal] = useState(false);
  const [announcementModal, setAnnouncementModal] = useState(false);
  const [teamToDQ, setTeamToDQ] = useState({});
  const [user, setUser] = useState({});
  const [currentNode, setCurrentNode] = useState();
  const [bottomScroll] = useState();
  const [slideOutWidth, setSlideOutWidth] = useState();
  const [slideOutHeight, setSlideOutHeight] = useState();
  const [error, setError] = useState('');

  const sideInfoRef = useRef();
  const disputeRef = useRef();

  useEffect(() => {
    getEvent(tournamentId);
    getTournament(tournamentId);
    fetchMatches(tournamentId);
    const interval = setInterval(() => {
      fetchMatches(tournamentId);
    }, 10000);
    const eventInterval = setInterval(() => {
      getEvent(tournamentId);
    }, 10000);
    return () => {
      clearInterval(interval);
      clearInterval(eventInterval);
    };
  }, []);

  useEffect(() => {
    if (tournament !== undefined) {
      getRulesSet(tournament.element_set_id);
      if (tournament.comment_id) {
        getComments(tournament.comment_id, tournament.id);
      }
      getClips(tournament.id);
      getDisputes(tournament.id);
      getTeams(tournament.id);
    }
    if (
      tournament !== undefined &&
      tournament.type !== 'Tournament::MatchEvent' &&
      Object.keys(tournamentBracket).length === 0
    ) {
      getTournamentBracket(tournament.id);
      initBracketSocket(tournament.id);
    }
  }, [tournament]);

  const handleJersey = async (color) => {
    setTeamToDQ((t) => ({ ...t, jersey_color: color }));
    try {
      setError('');
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/events/jersey`,
        {
          event_team_id: teamToDQ.id,
          color,
        },
      );
      getEvent(tournamentId);
      getTeams(tournamentId);
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    if (rules !== undefined) {
      getGameMode(rules.game_mode_id);
    }
  }, [rules]);

  const handleOpenTeamDQModal = (team) => {
    setTeamToDQ(team);
    setOpenTeamModal(true);
  };

  const handleOpenUserModal = (messageObject) => {
    setUser(messageObject);
    setUserModal(true);
  };

  const disqualifyTeamPopUp = () => {
    // eslint-disable-next-line no-alert
    const res = window.confirm('Are you sure!?');
    if (res) {
      disqualifyTeam(tournamentId, teamToDQ.id);
      setOpenTeamModal(false);
    }
  };

  const deleteCommentFromChat = () => {
    deleteComment(user.messageId);
    setUserModal(false);
  };

  const silenceUserForDay = () => {
    silenceUser(user.userId);
    setUserModal(false);
  };

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setCurrentNode(node);
      node.scrollTop = node.scrollHeight;
    }
  }, []);

  const onScroll = () => {
    const { scrollTop } = currentNode;

    if (scrollTop === 0 && isMore) {
      getMoreMessages();
      currentNode.scrollTo(0, 5);
    }
  };

  const handleReplay = async (matchId) => {
    try {
      const res = window.confirm('Are you sure!?');
      if (res) {
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/replay`,
        );

        fetchMatches(tournamentId);
        getDisputes(tournamentId);
      }
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    }
  };

  const handlePausePayout = async () => {
    try {
      setError('');
      const res = window.confirm('Are you sure!?');
      if (res) {
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/block_payout`,
        );

        getTournament(tournamentId);
      }
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };
  const handlePayoutNow = async () => {
    try {
      setError('');
      const res = window.confirm('Are you sure!?');
      if (res) {
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/complete_payout`,
        );
        getTournament(tournamentId);
      }
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };

  if (
    Object.keys(god).length === 0 ||
    tournament === undefined ||
    gameMode === undefined ||
    (tournament.type !== 'Tournament::MatchEvent' &&
      tournamentBracket === undefined)
  ) {
    return (
      <div className="mt-32">
        <Loader />
      </div>
    );
  }

  const formattetBracket =
    tournament.type !== 'Tournament::MatchEvent'
      ? formatBrackets(tournamentBracket, teams)
      : {};

  return (
    <Layout userId={god.id} showHeaderElements>
      <SlideOut
        isOpen={isPrizesOpen}
        handleOpenSlider={() => setIsPrizesOpen(false)}
        setSlideOutWidth={(width) => setSlideOutWidth(width)}
        setSlideOutHeight={(height) => setSlideOutHeight(height)}
      >
        <h2 className="text-3xl font-bold text-white">Prizes</h2>
        <div className="mt-3">
          {tournament.prizes.map((prize, i) => (
            <div
              key={prize.title}
              className="mt-3 flex items-center rounded-xl border border-grey-3 p-2"
            >
              {i === 0 && (
                <img src={firstPlaceIcon} alt={prize.title} className="w-10" />
              )}
              {i === 1 && (
                <img
                  src={secondndPlaceIcon}
                  alt={prize.title}
                  className="w-10"
                />
              )}
              {i === 2 && (
                <img src={thirdPlaceIcon} alt={prize.title} className="w-10" />
              )}
              <div className="ml-3">
                <h3 className="font-bold text-white">{prize.title}</h3>
                <p className="text-sm text-grey-2">{prize.description}</p>
              </div>
            </div>
          ))}
        </div>
      </SlideOut>
      <SlideOut
        isOpen={isRulesOpen}
        handleOpenSlider={() => setIsRulesOpen(false)}
        setSlideOutWidth={(width) => setSlideOutWidth(width)}
        setSlideOutHeight={(height) => setSlideOutHeight(height)}
      >
        <div
          className="scrollable-content mt-3"
          style={{ height: 'calc(100vh - 120px)' }}
        >
          <h3 className="text-2xl font-bold text-white">{gameMode.title}</h3>
          <p className="mt-1 text-sm text-grey-2">{gameMode.description}</p>
          <div className="mt-3 flex items-center rounded-xl border border-grey-3 p-2">
            <img
              src={rules.match_format.icon_url}
              alt={rules.match_format.title}
              className="w-8"
            />
            <div className="ml-3">
              <h3 className="text-xs text-grey-2">
                {rules.match_format.title}
              </h3>
              <p className="text-sm font-bold text-white">
                {rules.match_format.description}
              </p>
            </div>
          </div>
          <div className="mt-3 flex items-center rounded-xl border border-grey-3 p-2">
            <img src={gameMode.icon_url} alt={gameMode.id} className="w-8" />
            <div className="ml-3">
              <h3 className="text-xs text-grey-2">Game Mode</h3>
              <p className="text-sm font-bold text-white">{gameMode.name}</p>
            </div>
          </div>
          {rules.rule_types.map((rule) => (
            <div
              key={rule.id}
              className="mt-3 flex items-center rounded-xl border border-grey-3 p-2"
            >
              <img src={rule.icon_url} alt={rule.id} className="w-8" />
              <div className="ml-3">
                <h3 className="text-xs text-grey-2">{rule.title}</h3>
                <p className="text-sm font-bold text-white">
                  {rule.rule_option.name}
                </p>
              </div>
            </div>
          ))}
          <a
            href={`https://1v1me.com/tournament-rules/${tournament.element_set_id}`}
            className="mt-3 flex items-center rounded-xl border border-grey-3 p-2"
          >
            <img src={RulesWhiteImage} alt="rules-white" className="w-8" />
            <div className="ml-3">
              <h3 className="text-xs text-grey-2">Tournament Rules</h3>
              <p className="text-sm font-bold text-white">Click For Details</p>
            </div>
          </a>
        </div>
      </SlideOut>
      {tournament.type !== 'Tournament::MatchEvent' && (
        <Modal
          xl
          className="lg:w-full"
          modalIsOpen={isBracketOpen}
          closeModal={() => setIsBracketOpen(false)}
        >
          <div className="p-8">
            <BracketV2 brackets={tournamentBracket || []} teams={teams || []} />
          </div>
        </Modal>
      )}
      <div className="flex 2xl:m-auto">
        {tournament.type === 'Tournament::MatchEvent' && event ? (
          <MatchEventManager
            event={event}
            teams={teams}
            disputes={disputes || []}
            submitMatchPlacement={submitMatchPlacement}
            onReplay={(id) => handleReplay(id)}
            onComplete={() => getEvent(tournamentId)}
            onCountdown={() => getEvent(tournamentId)}
            handleOpenTeamDQModal={handleOpenTeamDQModal}
          />
        ) : (
          <>
            <div
              className={classNames(
                'relative col-span-4 mx-3 border-r border-grey-3',
              )}
            >
              <h3 className="mb-4 text-2xl font-bold text-white">
                Live Matches
              </h3>
              <div className="section">
                <div className="scrollable-content pb-3 pr-4">
                  {matches.map((m) => (
                    <MatchCard
                      match={m}
                      key={m.id}
                      tournament
                      canSetScores
                      onUpdateMatch={() => {
                        fetchMatches(tournamentId);
                        getDisputes(tournamentId);
                      }}
                      teams={teams
                        .map((t) => {
                          const found = m.participants.find((p) => {
                            const player = t.tournament_players.find(
                              (tp) => tp.user_profile.id === p.user_id,
                            );
                            return player;
                          });

                          return found
                            ? {
                                ...t,
                                team_uuid: found.team_uuid,
                              }
                            : null;
                        })
                        .filter(Boolean)}
                    />
                  ))}
                </div>
              </div>
            </div>
            {(tournament.type !== 'Tournament::MatchEvent' ||
              disputes.length > 0) && (
              <div className="relative col-span-4 mx-3">
                <h3 className="text-2xl font-bold text-white">Live Disputes</h3>
                <div className="absolute flex w-full flex-col">
                  <div className="section">
                    <div
                      ref={disputeRef}
                      className="scrollable-content w-full pb-3"
                    >
                      {disputes.length ? (
                        disputes.map(({ match, dispute }) => (
                          <DisputeCard
                            key={match.id}
                            disputeId={match.id}
                            participants={match.participants}
                            players={dispute.teams}
                            matchName={dispute.match_name}
                            submitMatchPlacement={submitMatchPlacement}
                            onReplay={() => handleReplay(match.id)}
                          />
                        ))
                      ) : (
                        <div className="mt-3 flex">
                          <div className="w-full rounded-md border-2 border-dashed border-grey-2 p-6">
                            <p className="text-center text-grey-2">
                              No disputes created for this tournament yet
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <Modal
        modalIsOpen={openTeamModal}
        closeModal={() => setOpenTeamModal(false)}
        header={`Team ${teamToDQ.display_team_name}`}
      >
        <div className="mt-6">
          <Jersey
            color={teamToDQ.jersey_color || '#fff'}
            onChange={handleJersey}
          />
          <br />
          {tournament.type !== 'Tournament::MatchEvent' && (
            <Button
              text="Disqualify Team"
              variant="warning"
              className="w-full"
              onClick={disqualifyTeamPopUp}
            />
          )}
        </div>
      </Modal>

      <Modal
        height="200px"
        modalIsOpen={openUserModal}
        closeModal={() => setUserModal(false)}
        header={`@${user.user}`}
      >
        <div className="mt-6">
          <Button
            text="Silence"
            variant="warning"
            className="w-full"
            onClick={silenceUserForDay}
          />
          <Button
            text={`Delete Message "${user.message}"`}
            variant="warning"
            className="mt-3 w-full"
            onClick={deleteCommentFromChat}
          />
        </div>
      </Modal>

      <AnnouncementModal
        isOpen={announcementModal}
        onClose={() => setAnnouncementModal(false)}
        matchId={event?.match_event_match_id}
      />
    </Layout>
  );
};

export default TournamentsManager;
