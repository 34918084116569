/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable indent */
import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import Button from '../../components/Buttons/Button/Button.js';
import useTournament from '../../contexts/TournamentsContext/TournamentsContext.js';

const TeamListPlayer = ({ team }) => {
  const firstPlayer = useMemo(() => {
    return team.roster.players.length
      ? [...team.roster.players].sort((a, b) => {
          if (a.type === 'leader' && b.type !== 'leader') return -1;
          if (a.type !== 'leader' && b.type === 'leader') return 1;
          return a.id - b.id;
        })[0]
      : null;
  }, [team.roster.players]);

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <div
        className="h-20 w-20 rounded-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${firstPlayer?.user_profile?.profile_image_url})`,
        }}
      />
      <p className="mt-4 text-3xl font-extrabold text-white">
        {firstPlayer?.user_profile?.username}
      </p>
    </div>
  );
};

const TeamListScore = ({ team }) => {
  const { event, teamsWithScores, fetchScores } = useTournament();

  const firstPlayer = useMemo(() => {
    return team.roster.players.length
      ? [...team.roster.players].sort((a, b) => {
          if (a.type === 'leader' && b.type !== 'leader') return -1;
          if (a.type !== 'leader' && b.type === 'leader') return 1;
          return a.id - b.id;
        })[0]
      : null;
  }, [team.roster.players]);

  const currentRound = teamsWithScores?.length
    ? Math.max(...teamsWithScores[0].scores.map((s) => s.round_number))
    : null;

  let teamScores = teamsWithScores.find(
    (t) => t.roster.id === team.roster.id,
  ).scores;
  if (event.play_info.num_of_rounds > 1 && event.state === 'live') {
    // for multiple round, use the current round score only
    // but only if we're live
    teamScores = teamScores.filter((s) => s.round_number === currentRound);
  }
  const score = teamScores.reduce((acc, curr) => acc + curr.score, 0) ?? 0;
  const isWinner = !!teamsWithScores.find(
    (t) => t.placement === 1 && t.roster.id === team.roster.id,
  );

  useEffect(() => {
    if (!teamsWithScores?.length) {
      fetchScores(event.match_event_match_id);
    }
  }, [event.match_event_match_id]);

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <p
        className={cn('mb-4 text-6xl font-extrabold text-white', {
          '!text-gold': isWinner,
        })}
      >
        {score ?? '-'}
      </p>
      <div className="flex flex-1 items-center justify-center gap-2">
        <div
          className="h-8 w-8 rounded-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${firstPlayer?.user_profile?.profile_image_url})`,
          }}
        />
        <p className="text-2xl font-extrabold text-white">
          {firstPlayer?.user_profile?.username}
        </p>
      </div>
    </div>
  );
};

const TeamCard = ({ team, active }) => {
  return active ? (
    <TeamListScore team={team} />
  ) : (
    <TeamListPlayer team={team} />
  );
};

const MainScore = ({ handleOpenTeamDQModal }) => {
  const { teams, event } = useTournament();

  if (!teams.length) {
    return (
      <div className="mt-3 flex">
        <div className="w-full rounded-md border-2 border-dashed border-grey-2 p-6">
          <p className="text-center text-grey-2">
            No teams singed up for for this tournament yet
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col overflow-hidden rounded-2xl border border-grey-4 bg-grey-5">
      <div className="flex w-full border-b border-grey-4">
        {teams.map((team, idx) => (
          <div
            key={`team${team.id}`}
            className={cn(
              'flex flex-1 flex-col items-center justify-center p-3',
              idx === 0 ? 'border-r border-grey-4' : '',
            )}
            style={{ backgroundColor: team.jersey_color }}
          >
            <h1 className="text-2xl font-bold text-white">
              {team?.tag?.title
                ? team.tag.title.toUpperCase()
                : team?.display_team_name?.toUpperCase?.()}
            </h1>
            <Button
              onClick={() => handleOpenTeamDQModal(team)}
              className={cn('text-white', team.jersey_color && 'hidden')}
              text="Select Jersey Color"
            />
          </div>
        ))}
      </div>

      <div className="mt-3 flex w-full p-8">
        <TeamCard team={event.teams[0]} active={event.active} />
        <div className="flex flex-1 flex-col items-center justify-center">
          <img
            width={64}
            src={event.play_info.game_icon}
            className="rounded-full"
            alt={event.play_info.game_name}
          />
          <p className="text-lg font-extrabold text-white">
            {event.play_info.game_mode_title}
          </p>
        </div>
        <TeamCard team={event.teams[1]} active={event.active} />
      </div>
    </div>
  );
};

export default MainScore;
