/* eslint-disable no-restricted-globals */
import React, { FC, useEffect, useState, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import client from '../../services/client';
import { Game, GameMode, Match, Scores } from '../../types';
import BaseModal from '../../components/BaseModal/BaseModal';
import Button from '../../components/Buttons/Button/Button';
import TrashCanIcon from '../../assets/Icons/trash.svg';

// @ts-ignore
import HoldButton from '../../components/HoldButton.tsx';
// @ts-ignore
import AvatarGroup from '../../components/AvatarGroup.tsx';

import useTournament from '../../contexts/TournamentsContext/TournamentsContext.js';

const ConfirmModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  teams: any[];
  scores: {
    /* eslint-disable no-undef */
    [round: number]: {
      [team: string]: number;
    };
  };
  error: string;
  onWinner: (id: string) => void;
}> = ({ isOpen, onClose, scores, teams, error, onWinner }) => {
  const [isLoading, setIsLoading] = useState(false);
  const team = useMemo(() => {
    const results = teams.reduce((prev, curr) => {
      const wins = {};
      Object.keys(scores).forEach((r) => {
        const winner = Object.keys(scores[r]).reduce((a, b) =>
          scores[r][a] > scores[r][b] ? a : b,
        );
        if (winner === curr.team_uuid) {
          wins[curr.team_uuid] = wins[curr.team_uuid]
            ? wins[curr.team_uuid] + 1
            : 1;
        }
      });
      return { ...prev, ...wins };
    }, {});

    const uuid = Object.keys(results).reduce(
      (a, b) => (results[a] > results[b] ? a : b),
      '',
    );
    return teams.find((t) => t.team_uuid === uuid);
  }, [scores, teams]);

  useEffect(() => {
    setIsLoading(false);
  }, [error]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (team) {
      onWinner(team.team_uuid);
    }
  };

  return (
    <BaseModal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header=""
      fullWidth={false}
      large
    >
      <div className="flex h-full flex-col items-center justify-center pt-12 sm:px-12">
        <AvatarGroup
          users={(team?.tournament_players || []).map(
            (p) => p.user_profile.profile_image_url,
          )}
          large
          limit={3}
        />
        <h3 className="mb-2 flex-1 pt-4 text-center text-3xl font-extrabold text-white">
          Confirm Team {team?.name} Won
        </h3>
        <div className="mx-auto max-w-lg">
          {error && (
            <p className="py-2 text-center text-sm text-warning">{error}</p>
          )}
          <p className="mb-4 text-center text-sm text-grey-2">
            THIS CANNOT BE UNDONE AND WILL PAYOUT ANY STAKERS
          </p>
          <div className="">
            <HoldButton
              variant="warning"
              text="Hold To Confirm"
              timeout={250}
              onConfirm={handleSubmit}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

const RoundRow: FC<{
  currRound?: number;
  round: number;
  roundScores: {
    [team: string]: number;
  };
  teams: any[];
  scoreType: GameMode['round_score_type'];
  onSave: (
    round: number,
    newScores: {
      [team: string]: number;
    },
  ) => void;
  isLoading: boolean;
  display: boolean;
  onDelete: (round: number) => void;
}> = ({
  round,
  roundScores,
  teams,
  scoreType,
  isLoading,
  onSave,
  display,
  onDelete,
  currRound,
}) => {
  const [scores, setScores] = useState(roundScores || {});

  useEffect(() => {
    setScores(roundScores || {});
  }, [roundScores]);

  const handleUpdateScore = (teamId: string, score: number) => {
    setScores((s) => ({
      ...s,
      [teamId]: score,
    }));
  };

  const handleToggle = (teamId: string) => {
    const newObj = { ...scores };

    teams.forEach((t) => {
      newObj[t.team_uuid] = 0;
    });
    newObj[teamId] = 1;
    setScores(newObj);
  };

  return (
    <div className="grid grid-cols-4 items-center gap-4 py-2 hover:bg-white/5">
      <p className="font-extrabold uppercase text-white">Round {round}</p>
      {(round === 1 || display) && (
        <>
          {teams.map((t) =>
            scoreType === 'points' ? (
              <input
                placeholder="Score"
                className="fade-in-fast w-full rounded-full bg-grey-4 py-2 px-4 text-sm text-white focus:outline-none"
                onChange={(event) =>
                  handleUpdateScore(t.team_uuid, Number(event.target.value))
                }
                value={scores?.[t.team_uuid] ?? ''}
              />
            ) : (
              <Button
                rounded
                text={t.name}
                large
                className="fade-in-fast"
                onClick={() => handleToggle(t.team_uuid)}
                variant={scores?.[t.team_uuid] ? 'primary' : 'tertiary'}
              />
            ),
          )}
          {Object.keys(scores).length > 1 && (
            <div className="grid grid-cols-3 gap-2">
              {isEqual(scores, roundScores) &&
                (!currRound || currRound === round) && (
                  <Button
                    text=""
                    variant="warning"
                    disabled={isLoading}
                    isLoading={isLoading}
                    onClick={() => onDelete(round)}
                    image={TrashCanIcon}
                    imageClass="w-4 saturate-0 brightness-[100]"
                    className="!rounded !p-2"
                  />
                )}
              <Button
                text={isEqual(scores, roundScores) ? 'Saved' : 'Save'}
                variant="primary"
                disabled={isLoading || isEqual(scores, roundScores)}
                isLoading={isLoading}
                onClick={() => onSave(round, scores)}
                className={classNames({
                  '!col-span-2': isEqual(scores, roundScores),
                  '!col-span-3': !isEqual(scores, roundScores),
                })}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ScoresModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  error: string;
  onWinner: (id: string) => void;
  hideFinalize?: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  currRound?: any;
}> = ({
  isOpen,
  onClose,
  error,
  onWinner,
  hideFinalize,
  onSave,
  onDelete,
  currRound,
}) => {
  const [scores, setScores] = useState<{
    /* eslint-disable no-undef */
    [round: number]: {
      [team: string]: number;
    };
  }>({});
  const [saving, setSaving] = useState(false);
  const [e, setError] = useState('');
  const [showConfirm, setShowConfirm] = useState(true);
  const [match, setMatch] = useState<Match | null>(null);
  const [gameMode, setGameMode] = useState<GameMode | null>(null);
  const { teams: tournamentTeams, event } = useTournament();

  const scoreType = gameMode?.round_score_type;
  const numRounds = event?.play_info.num_of_rounds;
  const matchId = event?.match_event_match_id;

  const teams = useMemo(
    () =>
      (tournamentTeams || [])
        .map((t) => {
          const found = match?.participants.find((p) => {
            const player = t.tournament_players.find(
              (tp) => tp.user_profile.id === p.user_id,
            );
            return player;
          });

          return found
            ? {
                ...t,
                team_uuid: found.team_uuid,
              }
            : null;
        })
        .filter(Boolean),
    [match, tournamentTeams],
  );

  const handleSave = (
    round: number,
    s: {
      [team: string]: number;
    },
  ) => {
    setError('');
    setSaving(true);
    const payload = {
      round_number: round,
      scores: Object.keys(s).map((t) => ({
        team_uuid: t,
        score: s[t],
      })),
    };
    client
      .put<Scores>(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/scores`,
        payload,
      )
      .then(() => setScores((sc) => ({ ...sc, [round]: s })))
      .catch((err) =>
        setError(err.response?.data?.message || 'Something went wrong'),
      )
      .finally(() => {
        setSaving(false);
        onSave?.();
      });
  };

  const handleDelete = (round: number) => {
    const res = confirm('Are you sure?');
    if (res) {
      setError('');
      setSaving(true);
      client
        .delete<Scores>(
          `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/scores?round_number=${round}`,
        )
        .then(() => {
          setScores((sc) => {
            const clone = { ...sc };
            delete clone[round];
            return clone;
          });

          onDelete?.();
        })
        .catch((err) =>
          setError(err.response?.data?.message || 'Something went wrong'),
        )
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const handleUpdateScore = (teamId: string, round: number, score: number) => {
    setScores((s) => ({
      ...s,
      [round]: {
        ...s[round],
        [teamId]: score,
      },
    }));
  };

  const handleToggle = (teamId: string, round: number) => {
    const newObj = { ...scores };
    if (!newObj[round]) {
      newObj[round] = {};
    }
    teams.forEach((t) => {
      newObj[round][t.team_uuid] = 0;
    });
    newObj[round][teamId] = 1;
    setScores(newObj);
  };

  const handleSubmit = async () => {
    setShowConfirm(true);
  };

  const getDisabled = () => {
    if (teams.length > 2) {
      return !scores[1] || Object.keys(scores[1]).length === 0;
    }
    const results = teams.reduce((prev, curr) => {
      const wins: string[] = [];
      Object.keys(scores).forEach((r) => {
        const winner = Object.keys(scores[r]).reduce((a, b) =>
          scores[r][a] > scores[r][b] ? a : b,
        );
        if (winner === curr.team_uuid) {
          wins.push(winner);
        }
      });
      return [...prev, wins];
    }, []);

    return !results.some((r) => r.length > numRounds / 2);
  };

  useEffect(() => {
    const fetchMatch = () =>
      client
        .get(`${process.env.REACT_APP_API_URL}/api/v1/matches/${matchId}`)
        .then(({ data: matchData }) => {
          setMatch(matchData);
          const cacheMode = sessionStorage.getItem(
            `game_mode_${matchData.game_mode_id}`,
          );
          if (cacheMode) {
            setGameMode(JSON.parse(cacheMode));
          } else {
            client
              .get(
                `${process.env.REACT_APP_API_URL}/api/v1/game_modes/${matchData.game_mode_id}`,
              )
              .then(({ data: gameModeData }) => {
                sessionStorage.setItem(
                  `game_mode_${matchData.game_mode_id}`,
                  JSON.stringify(gameModeData),
                );
                setGameMode(gameModeData);
              });
          }
        });

    if (matchId) {
      fetchMatch();
    }
  }, [matchId]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    client
      .get<Scores>(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/scores`,
      )
      .then(({ data }) => {
        const obj: typeof scores = {};
        data.forEach((r) => {
          if (!obj[r.round_number]) {
            obj[r.round_number] = {};
          }
          obj[r.round_number][r.team_uuid] = r.score;
        });
        setScores(obj);
      });
  }, [matchId, isOpen]);

  return (
    <BaseModal
      header="Set Scores"
      modalIsOpen={isOpen}
      closeModal={onClose}
      fullWidth={false}
      paginateData={console.log}
      large
    >
      {teams.length > 2 ? (
        <div className="px-4 sm:px-8">
          <div className="grid grid-cols-2 gap-4 py-2">
            {teams.map((t, i) => (
              <>
                <p
                  className="flex items-center gap-2 font-bold text-white"
                  key={`score_${t.team_uuid}`}
                >
                  <AvatarGroup
                    small
                    users={t.tournament_players.map((p) => p.user_profile)}
                    limit={3}
                  />
                  {t.name}
                </p>
                {scoreType === 'points' ? (
                  <input
                    placeholder="Score"
                    key={`score_${t.id}`}
                    className="fade-in-fast w-full rounded-full bg-grey-4 py-2 px-4 text-sm text-white focus:outline-none"
                    onChange={(ev) =>
                      handleUpdateScore(t.team_uuid, 1, Number(ev.target.value))
                    }
                    value={scores[1]?.[t.team_uuid] ?? ''}
                  />
                ) : (
                  <Button
                    rounded
                    text={t.name}
                    large
                    className="fade-in-fast"
                    onClick={() => handleToggle(t.team_uuid, 1)}
                    variant={scores[1]?.[t.team_uuid] ? 'primary' : 'tertiary'}
                  />
                )}
              </>
            ))}
          </div>
          {(error || e) && (
            <p className="pt-4 text-center text-sm text-warning">
              {e || error}
            </p>
          )}
          {!hideFinalize && (
            <div className="flex items-center justify-end pt-8">
              <Button
                rounded
                text="Finalize Scores"
                large
                variant="primary"
                disabled={getDisabled()}
                onClick={handleSubmit}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="px-4 sm:px-8">
          <div className="grid grid-cols-4 gap-4 py-2">
            <div />
            {teams.map((t) => (
              <p
                className="flex items-center gap-2 font-bold text-white"
                key={`score_${t.id}`}
              >
                <AvatarGroup
                  small
                  users={t.tournament_players.map((p) => p.user_profile)}
                  limit={3}
                />
                {t.name}
              </p>
            ))}
          </div>
          {Array.from(Array(numRounds).keys()).map((round) => {
            const r = Number(round) + 1;
            const display =
              (scores[round] && Object.keys(scores[round])?.length > 0) ||
              (scores[r] && Object.keys(scores[r])?.length > 0);

            return (
              <RoundRow
                key={`round_${r}`}
                round={r}
                roundScores={scores[r]}
                isLoading={saving}
                onSave={handleSave}
                teams={teams}
                scoreType={scoreType}
                display={display}
                onDelete={handleDelete}
                currRound={currRound}
              />
            );
          })}

          {(error || e) && (
            <p className="pt-4 text-center text-sm text-warning">
              {e || error}
            </p>
          )}
          {!hideFinalize && (
            <div className="flex items-center justify-end pt-8">
              <Button
                rounded
                text="Finalize Scores"
                large
                variant="primary"
                disabled={getDisabled()}
                onClick={handleSubmit}
              />
            </div>
          )}
        </div>
      )}
      <ConfirmModal
        teams={teams}
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        scores={scores}
        error={error}
        onWinner={onWinner}
      />
    </BaseModal>
  );
};

export default ScoresModal;
