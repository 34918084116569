import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Auth route handler
import RequireAuth from './components/RequireAuth/RequireAuth';

// pages
import LoginPage from './pages/Login/Login';
import User from './pages/UserManagement/UserManagement';
import Tournaments from './pages/Tournaments/Tournaments';
import TournamentsManagerV2 from './pages/TournamentManagement/TournamentManagementV2';
import TournamentsManager from './pages/TournamentManagement/TournamentManagement';
import Disputes from './pages/Disputes/Disputes';
import InviteMangagement from './pages/InviteManagement/InviteManagement';
import Wallet from './pages/Wallet';
import Marketing from './pages/Marketing';
import ClipDownloader from './pages/ClipDownloader';

import { AuthProvider } from './contexts/AuthContext/AuthContext';
import { UsersProvider } from './contexts/UserContext/UserContext';
import { SettledDisputesProvider } from './contexts/SettledDisputesContext/SettledDisputesContext';
import { WalletProvider } from './contexts/WalletContext/WalletContext';
import { ReportsProvider } from './contexts/RecentReportsContext/RecentReportsContext';
import { AuditsProvider } from './contexts/AuditLogContext/AuditLogContext';
import { PotentialDuplicatesProvider } from './contexts/PotentialDuplicatesContext/PotentialDuplicatesContext';
import { RecentMatchesProvider } from './contexts/RecentMatchesContext/RecentMatchesContext';
import { TournamentsProvider } from './contexts/TournamentsContext/TournamentsContext';
import { DisputesProvider } from './contexts/DisputesContext/DisputesContext';
import { MarketingProvider } from './contexts/MarketingContext';
import UpdateTournament from './pages/TournamentManagement/UpdateTournament';
import ImageUploader from './pages/ImageUploader/index.tsx';
import ReferralGenerator from './pages/Referrals/index.tsx';
import EmailBuilder from './pages/EmailBuilder';
import PartnerSchedule from './pages/PartnerManagement/PartnerSchedule';
import PartnerSettings from './pages/PartnerManagement/PartnerSettings';

const RedirectToExternal = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};

export default function Login() {
  return (
    <AuthProvider>
      <UsersProvider>
        <WalletProvider>
          <ReportsProvider>
            <AuditsProvider>
              <PotentialDuplicatesProvider>
                <RecentMatchesProvider>
                  <SettledDisputesProvider>
                    <TournamentsProvider>
                      <DisputesProvider>
                        <MarketingProvider>
                          <BrowserRouter>
                            <Routes>
                              <Route path="/" element={<LoginPage />} />
                              <Route
                                path="/godmode/users/:userId"
                                element={
                                  <RequireAuth>
                                    <User />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/godmode/tournaments"
                                element={
                                  <RequireAuth>
                                    <Tournaments />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/godmode/disputes"
                                element={
                                  <RequireAuth>
                                    <Disputes />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/godmode/tournament-management/:tournamentId/manage"
                                element={
                                  <RequireAuth>
                                    <UpdateTournament />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/godmode/tournament-management-v2/:tournamentId"
                                element={
                                  <RequireAuth>
                                    <TournamentsManagerV2 />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/godmode/tournament-management/:tournamentId"
                                element={
                                  <RequireAuth>
                                    <TournamentsManager />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/invite-management"
                                element={
                                  <RequireAuth>
                                    <InviteMangagement />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/users/:userId/wallet"
                                element={
                                  <RequireAuth>
                                    <Wallet />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/marketing"
                                element={
                                  <RequireAuth>
                                    <Marketing />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/marketing/clip-downloader"
                                element={
                                  <RequireAuth>
                                    <ClipDownloader />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/marketing/image-uploader"
                                element={
                                  <RequireAuth>
                                    <ImageUploader />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/marketing/email-builder"
                                element={
                                  <RequireAuth>
                                    <EmailBuilder />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/marketing/referrals"
                                element={
                                  <RequireAuth>
                                    <ReferralGenerator />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/godmode/moderation"
                                element={
                                  <RedirectToExternal url="https://1v1me.com/admin/moderation" />
                                }
                              />
                              <Route
                                path="/godmode/partners/:partnerId/availability"
                                element={
                                  <RequireAuth>
                                    <PartnerSchedule />
                                  </RequireAuth>
                                }
                              />
                              <Route
                                path="/godmode/partners/:partnerId/settings"
                                element={
                                  <RequireAuth>
                                    <PartnerSettings />
                                  </RequireAuth>
                                }
                              />
                            </Routes>
                          </BrowserRouter>
                        </MarketingProvider>
                      </DisputesProvider>
                    </TournamentsProvider>
                  </SettledDisputesProvider>
                </RecentMatchesProvider>
              </PotentialDuplicatesProvider>
            </AuditsProvider>
          </ReportsProvider>
        </WalletProvider>
      </UsersProvider>
    </AuthProvider>
  );
}
