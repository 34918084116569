import React from 'react';
import Countdown from 'react-countdown';

const VortexCountdown = ({ onComplete, event }) => (
  <div className="relative mb-16 mt-4 w-full py-16 sm:mt-8 lg:py-32">
    <img
      src="https://1v1me.com/Images/vortex.gif"
      alt="Vortex"
      className="absolute top-0 left-0 z-0 h-full w-full overflow-hidden rounded-2xl object-cover object-top opacity-5"
    />
    <div
      style={{
        WebkitTransformStyle: 'preserve-3d',
        WebkitTransform: 'translate3d(0, 0, 0)',
      }}
      className="pointer-events-none absolute top-0 left-0 z-10 h-full w-full overflow-hidden rounded-2xl object-cover object-center opacity-40 blur-2xl"
    >
      <img
        src={
          event.wide_image_url ||
          event.play_info.game_landscape_image_url ||
          'https://1v1me.com/Images/holder-stake.png'
        }
        className="h-full w-full object-cover object-center"
        alt="banner"
      />
    </div>

    <div className="relative z-10 mx-auto max-w-7xl">
      <p className="text-center text-xs font-bold uppercase tracking-[6px] text-white">
        Starting In
      </p>
      <p className="text-center text-5xl font-[900] leading-none text-gold md:text-6xl 2xl:text-[100px]">
        <Countdown
          date={new Date(event.start_date)}
          onComplete={onComplete}
          renderer={(cd) => (
            <>
              {cd.days > 0 && `${cd.formatted.days}:`}
              {cd.hours > 0 && `${cd.formatted.hours}:`}
              {cd.formatted.minutes}:{cd.formatted.seconds}
            </>
          )}
        />
      </p>
    </div>
  </div>
);

export default VortexCountdown;
