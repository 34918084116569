import React, { useState, useMemo, useEffect } from 'react';
import Button from '../../components/Buttons/Button/Button';
import client from '../../services/client';
import BaseModal from '../../components/BaseModal/BaseModal';
import HoldButton from '../../components/HoldButton';
import ScoresModal from '../../features/Matches/ScoresModalV2.tsx';
import { useTournament } from '../../contexts/TournamentsContext';

const CancelEventModal = ({ isOpen, onClose, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [canceller, setCanceller] = useState(null);
  const {
    // loading,
    teams,
    tournament,
    getTournament,
  } = useTournament(true);

  const selectedName = useMemo(() => {
    if (canceller === '1v1Me') {
      return '1v1Me';
    }
    return teams.find((t) => t.id === canceller)?.display_team_name;
  }, [teams, canceller]);

  useEffect(() => {
    if (isOpen) {
      setError('');
      setIsLoading(false);
      setCanceller(null);
    }
  }, [isOpen]);

  const handleCancel = async () => {
    let endpoint = `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournament.id}`;
    if (canceller && canceller !== '1v1Me') {
      endpoint += `?tournament_team_id=${canceller}`;
    }
    try {
      setIsLoading(true);
      await client.delete(endpoint);
      getTournament(tournament.id);
      onSuccess();
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <BaseModal
      header=""
      height="auto"
      modalIsOpen={isOpen}
      closeModal={onClose}
    >
      {!canceller ? (
        <div className="pb-4 text-center">
          <h3 className="mb-8 text-3xl font-extrabold text-white">
            Who is Cancelling?
          </h3>
          <div className="flex flex-col gap-2">
            {teams.map((t) => (
              <Button
                key={t.display_team_name}
                text={t.display_team_name}
                variant="grey"
                rounded
                large
                className="!w-full !bg-grey-3 !text-warning"
                onClick={() => setCanceller(t.id)}
              />
            ))}
            <Button
              text="1v1Me"
              variant="grey"
              rounded
              large
              className="!w-full !bg-grey-3 !text-warning"
              onClick={() => setCanceller('1v1Me')}
            />
          </div>
        </div>
      ) : (
        <div className="pb-4 text-center">
          <h3 className="mb-2 text-3xl font-extrabold text-white">
            Confirm {selectedName} Is Cancelling
          </h3>
          <div className="mb-8 flex items-center justify-center">
            <Button
              variant="secondary"
              text="Switch Team"
              onClick={() => setCanceller(null)}
            />
          </div>
          <div className="w-full">
            {error && <p className="p-4 text-center text-warning">{error}</p>}
            <p className="mb-2 text-center text-xs text-grey-2">
              THIS CANNOT BE UNDONE AND WILL REFUND ANY STAKERS
            </p>
            <div>
              <HoldButton
                variant="warning"
                text="Hold To Confirm"
                timeout={250}
                onConfirm={handleCancel}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </BaseModal>
  );
};

function MatchSettings() {
  const { event, scores, fetchScores } = useTournament();
  const [error, setError] = useState('');
  const [cancelMatch, setCancelMatch] = useState(false);
  const [settingScores, setSettingScores] = useState(false);
  const { tournament, getTournament } = useTournament();

  const handlePausePayout = async () => {
    try {
      setError('');
      const res = window.confirm('Are you sure!?');
      if (res) {
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournament.id}/block_payout`,
        );
        getTournament(tournament.id);
      }
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <div className="bg-gray-5 my-4 flex flex-col gap-4 rounded-xl border border-grey-4 p-4">
      <h3 className="text-lg font-bold text-white">Match Settings</h3>
      <Button
        text={tournament?.lock ? 'Payout Blocked' : 'Block Payout'}
        variant="tertiary"
        disabled={tournament?.lock}
        rounded
        large
        className="whitespace-nowrap !px-6 disabled:opacity-50"
        onClick={handlePausePayout}
      />
      <Button
        text="Manual Score Entry"
        variant="grey"
        rounded
        large
        className="!px-6"
        onClick={() => setSettingScores(true)}
      />
      <Button
        text="Cancel Match"
        variant="grey"
        rounded
        large
        className="whitespace-nowrap !bg-warning !px-6"
        onClick={() => setCancelMatch(true)}
      />
      {error && <p className="text-red-500">{error}</p>}

      <CancelEventModal
        isOpen={cancelMatch}
        onClose={() => setCancelMatch(false)}
        onSuccess={() => {
          setCancelMatch(false);
        }}
      />

      <ScoresModal
        currRound={
          scores?.find((s) => !s.completed)?.round_number ||
          scores?.[scores.length - 1]?.round_number
        }
        isOpen={settingScores}
        onClose={() => setSettingScores(false)}
        error={error}
        onSave={() => {
          fetchScores(event.match_event_match_id);
        }}
        onDelete={() => {
          fetchScores(event.match_event_match_id);
        }}
        hideFinalize
      />
    </div>
  );
}

export default MatchSettings;
