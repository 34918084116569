import React, { useState } from 'react';
import { isAfter, addMinutes } from 'date-fns';
import Button from '../../components/Buttons/Button/Button';
import BaseModal from '../../components/BaseModal/BaseModal';
import client from '../../services/client.js';

import useTournament from '../../contexts/TournamentsContext/TournamentsContext.js';

function StartGame({ round, match, onStart }) {
  const [blame, setBlame] = useState(false);
  const [error, setError] = useState('');
  const { event } = useTournament();

  // if last round, do not show start game button
  if (round > event.play_info.num_of_rounds) {
    return null;
  }

  const handleStart = async (startRound, blameId) => {
    try {
      const payload = {
        round_number: startRound,
      };
      if (blameId) {
        payload.late_tournament_team_id = blameId;
      }
      setError('');
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${match.id}/play_by_plays/start_round`,
        payload,
      );

      onStart();
      setBlame(false);
    } catch (e) {
      console.log('StartGame error', e);
      setError(
        e.response?.data?.message || e.message || 'Something went wrong',
      );
    }
  };

  return (
    <div className="mx-auto flex h-full w-full max-w-7xl flex-col items-center justify-center gap-4 px-4">
      <h3 className="text-3xl font-extrabold">Start Game {round}</h3>
      {error && <p className="text-center text-warning">{error}</p>}
      <Button
        large
        rounded
        variant="primary"
        text={`Start Game ${round}`}
        onClick={() => {
          if (
            round === 1 &&
            isAfter(new Date(), addMinutes(new Date(event.start_date), 5))
          ) {
            setBlame(true);
          } else {
            handleStart(round);
          }
        }}
      />
      <BaseModal
        height="auto"
        modalIsOpen={blame}
        closeModal={() => setBlame(false)}
      >
        <div className="pb-4 text-center">
          <h3 className="mb-8 text-3xl font-extrabold text-white">
            Who was late?
          </h3>
          <div className="flex flex-col gap-2">
            {event.teams.map((t) => (
              <Button
                key={`${t.roster.id}-blame`}
                text={t.roster.team_name}
                variant="grey"
                rounded
                large
                className="!w-full !bg-grey-3 !text-warning"
                onClick={() => handleStart(1, t.roster.id)}
              />
            ))}
            <Button
              text="1v1Me"
              variant="grey"
              rounded
              large
              className="!w-full !bg-grey-3 !text-warning"
              onClick={() => handleStart(1)}
            />
          </div>
          {error && (
            <p className="pt-4 text-center text-sm text-warning">{error}</p>
          )}
        </div>
      </BaseModal>
    </div>
  );
}

export default StartGame;
