/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import { Popover, Transition } from '@headlessui/react';
import { usePrevious } from 'react-use';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { addMinutes, isAfter } from 'date-fns';
import classNames from 'classnames';
import client from '../../services/client.js';
import PlayByPlay from './PlayByPlay.js';
import ScoreCard from './ScoreCardV2.jsx';
import DownIcon from '../../assets/Icons/chevron.svg';
import ScoreOptions from './ScoreOptionsV2.jsx';
import DisputeCard from '../../components/DisputeCard/DisputeCard.js';

import TeamsList from '../../features/TeamsList/TeamsListV2.jsx';
import MatchSettings from './MatchSettings.jsx';
import ConfirmWinModal from './ConfirmWinner.jsx';
import EventStatus from './EventStatus.jsx';
import AnnouncementDrawer from './AnnouncementDrawer.jsx';

const MatchEventManager = ({
  event,
  teams,
  disputes,
  onReplay,
  onComplete,
  handleOpenTeamDQModal,
}) => {
  const [television, setTelevision] = useState();
  const [scoresData, setScoresData] = useState();
  const [match, setMatch] = useState();
  const [options, setOptions] = useState([]);
  const [round, setRound] = useState(1);
  const [completing, setCompleting] = useState(false);
  const subscription = useRef();

  useEffect(() => {
    if (event.match_event_match_id) {
      import('@rails/actioncable').then(({ createConsumer }) => {
        const cable = createConsumer(process.env.REACT_APP_WEBSOCKET_URL);

        const updateSubscription = cable.subscriptions.create(
          {
            channel: 'MatchRoundsChannel',
            match_id: event.match_event_match_id,
          },
          {
            received(data) {
              setScoresData(data);
            },
          },
        );

        subscription.current = updateSubscription;
      });
    }

    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, [event.match_event_match_id]);

  const resetState = () => {
    setTelevision(null);
    setScoresData(null);
    setMatch(null);
    setOptions([]);
    setRound(1);
  };

  const fetchMatch = (id) =>
    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/matches/${id}`)
      .then(({ data }) => {
        setMatch(data);
        const cacheMode = sessionStorage.getItem(
          `game_mode_${data.game_mode_id}`,
        );
        if (cacheMode) {
          // set game mode
        } else {
          client
            .get(
              `${process.env.REACT_APP_API_URL}/api/v1/game_modes/${data.game_mode_id}`,
            )
            .then(({ data }) => {
              sessionStorage.setItem(
                `game_mode_${data.game_mode_id}`,
                JSON.stringify(data),
              );
            });
        }
      });

  const fetchScores = (id) =>
    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/matches/${id}/scores`)
      .then(({ data }) => setScoresData(data));

  const fetchOptions = (id) =>
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${id}/play_by_plays/options`,
      )
      .then(({ data }) => setOptions(data));

  const fetchData = (tvId, matchId) => {
    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/tv/${tvId}`)
      .then(({ data }) => {
        setTelevision(data);
      });
    fetchScores(matchId);
    fetchOptions(matchId);
    fetchMatch(matchId);
  };

  useEffect(() => {
    if (event.television?.id && event.match_event_match_id) {
      fetchData(event.television.id, event.match_event_match_id);
    } else {
      resetState();
    }
  }, [event.television?.id]);

  const [error, setError] = useState('');

  const maxRound = useMemo(() => {
    if (!scoresData?.length) {
      return 1;
    }
    return Math.max(...scoresData.map((s) => s.round_number));
  }, [scoresData]);

  const team1UUID = useMemo(
    () =>
      television?.television_channel_participants.find((p) =>
        event.teams[0].roster.players.some(
          (player) => player.user_profile.id === p.user_profile.id,
        ),
      )?.group_identifier,
    [event.teams, television?.television_channel_participants],
  );

  const team2UUID = useMemo(
    () =>
      television?.television_channel_participants.find((p) =>
        event.teams[1].roster.players.some(
          (player) => player.user_profile.id === p.user_profile.id,
        ),
      )?.group_identifier,
    [event.teams, television?.television_channel_participants],
  );

  const { team1Wins, team2Wins, team1Scores, team2Scores } = useMemo(() => {
    if (event.completed) {
      const { team1Scores, team1Wins } = (
        event.teams[0].match_round_results || []
      ).reduce(
        (prev, curr) => ({
          team1Scores: [...prev.team1Scores, curr],
          team1Wins:
            curr.won && curr.completed ? prev.team1Wins + 1 : prev.team1Wins,
        }),
        { team1Scores: [], team1Wins: 0 },
      );
      const { team2Scores, team2Wins } = (
        event.teams[1].match_round_results || []
      ).reduce(
        (prev, curr) => ({
          team2Scores: [...prev.team2Scores, curr],
          team2Wins:
            curr.won && curr.completed ? prev.team2Wins + 1 : prev.team2Wins,
        }),
        { team2Scores: [], team2Wins: 0 },
      );
      return { team1Scores, team1Wins, team2Scores, team2Wins };
    }
    return (scoresData || []).reduce(
      (prev, curr) => {
        if (curr.team_uuid === team1UUID) {
          return {
            ...prev,
            team1Scores: [...prev.team1Scores, curr],
            team1Wins:
              curr.won && curr.completed ? prev.team1Wins + 1 : prev.team1Wins,
          };
        }
        if (curr.team_uuid === team2UUID) {
          return {
            ...prev,
            team2Scores: [...prev.team2Scores, curr],
            team2Wins:
              curr.won && curr.completed ? prev.team2Wins + 1 : prev.team2Wins,
          };
        }
        return prev;
      },
      { team1Scores: [], team1Wins: 0, team2Scores: [], team2Wins: 0 },
    );
  }, [event, scoresData, team1UUID, team2UUID]);

  const teamHasMajority = useMemo(
    () =>
      team1Wins > event.play_info.num_of_rounds / 2 ||
      team2Wins > event.play_info.num_of_rounds / 2,
    [event, team1Wins, team2Wins],
  );

  const canSubmit = useMemo(
    () =>
      !event.completed &&
      !event.teams.some((t) => t.placement && t.placement > 0) &&
      scoresData?.length > 0 &&
      teamHasMajority,
    [event, scoresData, teamHasMajority],
  );

  const leader =
    event.teams.length <= 1
      ? undefined
      : event.teams[team1Wins > team2Wins ? 0 : 1];

  const prevSubmit = usePrevious(canSubmit);

  useEffect(() => {
    if (!prevSubmit && canSubmit) {
      setCompleting(true);
    }
  }, [leader, prevSubmit, canSubmit]);

  const uuid = team1Wins > team2Wins ? team1UUID : team2UUID;

  const currentRound = scoresData?.length
    ? Math.max(...scoresData.map((s) => s.round_number))
    : null;
  const activeRound = scoresData?.filter(
    (s) => s.round_number === currentRound && !s.completed,
  );
  const completed =
    event.completed || event.teams.some((t) => t.placement && t.placement > 0);

  return (
    <div
      className="flex grid w-full grid-cols-12 flex-col overflow-hidden border-t border-grey-4"
      style={{ height: 'calc(100vh - 80px)' }}
    >
      <div className="relative col-span-8 flex flex-col border-r border-grey-4 p-8">
        <TeamsList handleOpenTeamDQModal={handleOpenTeamDQModal} />
        <EventStatus />
        {activeRound?.length > 0 && event.state === 'live' ? (
          <ScoreOptions
            options={options}
            matchId={event.match_event_match_id}
            team1UUID={team1UUID}
            team2UUID={team2UUID}
          />
        ) : null}

        <AnnouncementDrawer />
      </div>

      <div className="col-span-4 flex min-h-0 flex-col p-8">
        <ScoreCard
          match={match}
          television={television}
          onFinalize={() => {
            fetchData(event.television?.id, event.match_event_match_id);
            if (round < event.play_info.num_of_rounds) {
              setRound(round + 1);
            }
          }}
        />
        <MatchSettings />
        <div className="overflow-auto">
          <PlayByPlay
            event={event}
            matchId={event.match_event_match_id}
            currRound={scoresData?.find((s) => !s.completed)?.round_number}
          />
        </div>
      </div>

      <ConfirmWinModal
        isOpen={completing}
        onClose={() => setCompleting(false)}
        leader={leader}
        uuid={uuid}
        onComplete={() => onComplete()}
      />
    </div>
  );
};

export default MatchEventManager;
