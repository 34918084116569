import React, { useState } from 'react';
import HoldButton from '../../components/HoldButton.tsx';
import BaseModal from '../../components/BaseModal/BaseModal.js';
import client from '../../services/client.js';

const ConfirmWinModal = ({ isOpen, onClose, leader, uuid, onComplete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setError('');
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/submit`,
        {
          team_uuid: uuid,
          placement: 1,
        },
      );
      onComplete();
      onClose();
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseModal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header=""
      fullWidth={false}
      large
    >
      <div className="flex h-full flex-col items-center justify-center py-12 sm:px-12">
        <img
          src={
            leader.roster.players.find(
              (p) =>
                p.type === 'leader' || p.type === 'TournamentPlayer::Leader',
            )?.user_profile.profile_image_url ||
            leader.roster.players[0].user_profile.profile_image_url
          }
          alt="player"
          className="h-[64px] w-[64px] rounded-full object-cover"
        />
        <h3 className="mb-2 flex-1 pt-4 text-center text-3xl font-extrabold text-white">
          Confirm {leader.roster.team_name} Won
        </h3>
        <div className="mx-auto max-w-lg">
          {error && (
            <p className="py-2 text-center text-sm text-warning">{error}</p>
          )}
          <p className="mb-4 text-center text-sm text-grey-2">
            THIS CANNOT BE UNDONE AND WILL PAYOUT ANY STAKERS
          </p>
          <div className="">
            <HoldButton
              variant="warning"
              text="Hold To Confirm"
              timeout={250}
              onConfirm={handleSubmit}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ConfirmWinModal;
