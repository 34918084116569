/* eslint-disable no-alert */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import cn from 'classnames';
import BaseModal from '../../components/BaseModal/BaseModal';
import Button from '../../components/Buttons/Button/Button';
import client from '../../services/client';
import useTournament from '../../contexts/TournamentsContext/TournamentsContext.js';

const LeftKeyIndexMap = {
  0: 'A',
  1: 'S',
  2: 'D',
  3: 'F',
  4: 'Q',
  5: 'W',
  6: 'E',
  7: 'R',
  99: 'Z',
};

const RightKeyIndexMap = {
  0: 'H',
  1: 'J',
  2: 'K',
  3: 'L',
  4: 'U',
  5: 'I',
  6: 'O',
  7: 'P',
  99: 'M',
};

function ScoreOptionButton({ option, handleHotkeyPress, hasSelected }) {
  const [pressed, setPressed] = useState(false);
  const timerRef = React.useRef(0);

  const hasLongPress = () => {
    const p = timerRef.current;
    const r = Date.now();
    // press and release within 500ms
    return r - p > 500;
  };

  useEffect(() => {
    if (hasSelected) {
      return null;
    }

    const handleKeyDown = (e) => {
      if (e.key.toUpperCase() === option.hotkey) {
        setPressed(true);

        if (!e.repeat) {
          timerRef.current = Date.now();
        } else if (hasLongPress()) {
          handleHotkeyPress();
          setPressed(false);
        }
      }
    };

    const handleKeyUp = (e) => {
      if (e.key.toUpperCase() === option.hotkey) {
        setPressed(false);
        if (hasLongPress()) {
          handleHotkeyPress();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [option.hotkey, hasSelected, pressed]);

  useEffect(() => {
    if (!pressed && timerRef.current !== 0) {
      timerRef.current = 0;
    }
  }, [pressed]);

  if (hasSelected) {
    return null;
  }

  return (
    <button
      className="flex flex-col items-center justify-center gap-4 rounded-xl bg-grey-4 p-6 text-white"
      type="button"
      onClick={handleHotkeyPress}
    >
      <div className="flex flex-row items-center justify-center gap-2">
        {option.button_img && (
          <img
            src={option.button_img}
            width={24}
            height={24}
            alt="btn_img"
            className="h-8 w-8 rounded-full object-cover"
          />
        )}
        <span className="text-md whitespace-nowrap font-bold">
          {option.button_text}
        </span>
      </div>
      <div
        className={cn(
          'flex h-12 w-12 items-center justify-center rounded-lg border border-[#444444] bg-[#333333] shadow-lg shadow-[#333333]/50 active:translate-y-0.5 active:shadow-md',
          pressed && 'translate-y-0.5 shadow-md',
        )}
      >
        {option.hotkey}
      </div>
    </button>
  );
}

const ScoreOptions = ({ options, matchId, team1UUID, team2UUID }) => {
  const { scores, fetchScores } = useTournament();
  const currentRound = scores?.length
    ? Math.max(...scores.map((s) => s.round_number))
    : null;
  const [scoreOption, setScoreOption] = useState(null);

  const handleHotkeyPress = debounce((option) => {
    setScoreOption({
      ...option,
      matchId,
      round: currentRound,
    });
  }, 500);

  const handleComplete = () => {
    setScoreOption(null);
    fetchScores(matchId);
  };

  const primary = options
    .filter((v) => v.primary_option)
    .map((v, idx) => ({
      ...v,
      hotkey: LeftKeyIndexMap[idx],
    }));
  const secondary = options.filter((v) => !v.primary_option);

  return (
    <div className="flex justify-center p-6">
      <div
        className="grid gap-2"
        style={{
          gridTemplateColumns: `repeat(${Math.min(primary.length, 4)}, minmax(0, 1fr))`,
          gridTemplateRows: `repeat(${Math.ceil(primary.length / 4)}, minmax(0, 1fr))`,
        }}
      >
        {scoreOption
          ? null
          : primary.map((v) => (
              <ScoreOptionButton
                key={`${v.id}-primary`}
                option={v}
                hasSelected={!!scoreOption}
                handleHotkeyPress={() => handleHotkeyPress(v)}
              />
            ))}
      </div>

      <ScoreOptionModal
        isOpen={!!scoreOption}
        onClose={() => setScoreOption(null)}
        option={scoreOption}
        onComplete={handleComplete}
      />
    </div>
  );
};

const ScoreOptionModal = ({
  isOpen,
  onClose,
  disabled,
  option,
  onComplete,
}) => {
  const { event, teams } = useTournament();

  const handleScore = async (playerId, teamId) => {
    try {
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${option.matchId}/play_by_plays`,
        {
          team_id: teamId,
          user_id: playerId,
          round_number: option.round,
          play_title: option.play_title,
          stat_key: option.stat_key,
          score: option.score,
        },
      );
      onComplete?.();
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <BaseModal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header={option?.button_text ?? ''}
      backgroundColor="bg-grey-5"
      large
    >
      <div className="m-4 flex flex-col gap-4 rounded-xl border border-grey-4">
        <div className="flex flex-row">
          {teams.map((team, teamIdx) => {
            const hotKeyMap =
              teamIdx === 0 ? LeftKeyIndexMap : RightKeyIndexMap;

            // for a teamId (tournament team), we need the equivalent event.teams.roster.teamId
            const eventTeam = event.teams.find(
              (t) => t.roster.code === team.code,
            );

            return (
              <div key={team.name} className="flex flex-1 flex-col">
                <div
                  className="flex flex-1 items-center justify-center p-4"
                  style={{ backgroundColor: team.jersey_color }}
                >
                  <div className="text-white">
                    {team?.tag?.title
                      ? team.tag.title.toUpperCase()
                      : team?.display_team_name?.toUpperCase?.()}
                  </div>
                </div>

                <div className="flex flex-1 items-center justify-center p-4">
                  {team.tournament_players.map((player, playerIdx) => (
                    <ScoreOptionButton
                      key={`${player.id}-primary`}
                      option={{
                        hotkey: hotKeyMap[playerIdx],
                        button_text: player.user_profile.username,
                        button_img: player.user_profile.profile_image_url,
                      }}
                      handleHotkeyPress={() =>
                        handleScore(
                          player.user_profile.id,
                          eventTeam.roster.team_id,
                        )
                      }
                    />
                  ))}
                  {event.play_info.num_of_players > 1 && (
                    <ScoreOptionButton
                      option={{
                        hotkey: hotKeyMap[99],
                        button_text: 'Unknown',
                      }}
                      handleHotkeyPress={() => handleScore()}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </BaseModal>
  );
};

const ConfirmModal = ({ isOpen, onClose, event, data, onComplete }) => {
  const team = event.teams.find((t) => t.roster.team_id === data?.teamId);

  return (
    <BaseModal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header=""
      fullWidth={false}
      large
    >
      {team && (
        <div className="flex h-full flex-col items-center justify-center pt-12 sm:px-12">
          <img
            src={
              team.roster.players.find(
                (p) =>
                  p.type === 'leader' || p.type === 'TournamentPlayer::Leader',
              )?.user_profile.profile_image_url ||
              team.roster.players[0].user_profile.profile_image_url
            }
            alt="player"
            className="h-[64px] w-[64px] rounded-full object-cover"
          />
          <h3 className="mb-2 flex-1 pt-4 text-center text-3xl font-extrabold text-white">
            Confirm {team.roster.team_name} Won <br /> By{' '}
            {data?.data.button_text}
          </h3>
          <div className="mx-auto w-full max-w-lg">
            <div className="">
              <Button
                variant="primary"
                text="Confirm"
                rounded
                large
                className="!w-full"
                onClick={onComplete}
              />
            </div>
          </div>
        </div>
      )}
    </BaseModal>
  );
};

export default ScoreOptions;
