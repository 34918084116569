/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';

import Button from '../../components/Buttons/Button/Button.js';
import client from '../../services/client.js';
import useTournament from '../../contexts/TournamentsContext/TournamentsContext.js';
import DownIcon from '../../assets/Icons/chevron.svg';

const AnnouncementDrawer = () => {
  const { event } = useTournament();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [announcementTemplates, setAnnouncementTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [value, setValue] = useState('');

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSend = async () => {
    try {
      setLoading(true);
      setError('');
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${event?.match_event_match_id}/play_by_plays/announcement`,
        {
          description: value,
        },
      );

      setAnnouncements([
        {
          description: value,
          id: announcements.length + 1,
          created_at: new Date(),
        },
        ...announcements,
      ]);
    } catch (e) {
      setError(
        e.response?.data?.message || e.message || 'Something went wrong',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = (text) => {
    setValue(text);
  };

  useEffect(() => {
    const fn = async () => {
      const res = await client.get(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${event.match_event_match_id}/play_by_plays/announcements`,
      );
      setAnnouncements(res.data);
    };
    if (drawerOpen && event?.match_event_match_id) {
      fn();
    }
  }, [drawerOpen, event?.match_event_match_id]);

  useEffect(() => {
    const fn = async () => {
      const res = await client.get(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${event.match_event_match_id}/play_by_plays/announcement_templates`,
      );
      setAnnouncementTemplates(res.data);
    };
    if (drawerOpen && event?.match_event_match_id) {
      fn();
    }
  }, [drawerOpen, event?.match_event_match_id]);

  if (!event?.match_event_match_id) return null;

  return (
    <div
      className={`absolute left-0 bottom-0 right-8 z-50 flex flex-col rounded-t-lg border-t border-l border-r border-grey-4 bg-black transition-all duration-300 ease-in-out ${
        drawerOpen
          ? 'h-[800px] shadow-[0_0_15px_rgba(255,255,255,0.25)]'
          : 'max-h-[80px]'
      }`}
    >
      <div
        className="flex cursor-pointer items-center justify-between bg-grey-5 p-6 text-white"
        onClick={toggleDrawer}
      >
        <p className="text-xl font-extrabold">Announcements</p>
        <img
          src={DownIcon}
          alt="expand"
          className={`transform transition-transform ${
            !drawerOpen ? '-rotate-90' : 'rotate-90'
          }`}
        />
      </div>
      <div className="flex flex-1 flex-col gap-4 p-6">
        <div className="flex max-h-[400px] flex-1 flex-col gap-4 overflow-y-auto">
          {announcements.map((a) => (
            <div key={a.id} className="rounded-lg bg-grey-4 p-4 text-white">
              <div className="text-xs text-grey-2">
                {new Date(a.created_at).toLocaleString()}
              </div>
              <div>{a.description}</div>
            </div>
          ))}
        </div>

        <div className="gap-2">
          <Popover className="relative z-10 w-full rounded-xl border border-grey-3 bg-grey-4 p-2">
            <div className="flex items-center justify-between p-2">
              <div className="flex flex-col justify-center gap-1">
                <p className="font-bold text-white">Choose Message Template</p>
                <p className="text-grey-2">
                  Choose a default announcement that can be edited
                </p>
              </div>

              <Popover.Button className="flex items-center gap-4 rounded-full bg-grey-4 px-4 py-2 text-white !outline-none !ring-0 transition-opacity ease-in-out hover:opacity-75">
                Select Template
                <img
                  src={DownIcon}
                  alt="expand"
                  className="w-2 rotate-90 opacity-70"
                />
              </Popover.Button>
            </div>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform translate-y-2 opacity-0"
              enterTo="transform translate-y-0 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform translate-y-0 opacity-100"
              leaveTo="transform translate-y-2 opacity-0"
            >
              <Popover.Panel className="absolute bottom-16 right-0 z-10 h-[400px] w-[500px] overflow-y-auto overflow-x-hidden rounded-lg bg-grey-3 shadow-lg">
                {({ close }) => (
                  <div onClick={() => close()}>
                    {[
                      ...announcementTemplates,
                      { title: 'Custom', text: 'afsaf' },
                      { title: 'Custom1', text: 'afsaf1' },
                      { title: 'Custom2', text: 'afsaf2' },
                    ].map((a) => (
                      <div
                        key={a.title}
                        className="relative overflow-hidden border-b border-grey-4 p-4 text-white"
                      >
                        <p className="line-clamp-2 overflow-hidden text-ellipsis [-webkit-line-clamp:2] [display:-webkit-box] [-webkit-box-orient:vertical]">
                          {a.text}
                        </p>
                        <div className="absolute top-0 right-0 p-2">
                          <Button
                            text="Use"
                            variant="secondary"
                            small
                            className="bg-grey-4"
                            onClick={() => handleAdd(a.text)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>

        <div className="flex flex-col items-center gap-4">
          <textarea
            className="w-full rounded-lg bg-grey-4 py-3 px-4 text-sm text-white focus:outline-none"
            rows={4}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            placeholder="Type a custom announcement here..."
          />
          <div className="flex w-full items-center justify-end gap-4">
            {error && <p className="text-center text-warning">{error}</p>}
            <Button
              text="Submit"
              variant="primary"
              rounded
              large
              onClick={handleSend}
              isLoading={loading}
              disabled={loading || !value.trim().length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementDrawer;
