import React, { useEffect, useState } from 'react';
import Button from '../../components/Buttons/Button/Button.js';
import Modal from '../../components/BaseModal/BaseModal.js';
import client from '../../services/client.js';

const AnnouncementModal = ({ isOpen, onClose, matchId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue('');
  }, [isOpen]);

  const handleSend = async () => {
    try {
      setLoading(true);
      setError('');
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/play_by_plays/announcement`,
        {
          description: value,
        },
      );
      onClose();
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  if (!matchId) return null;

  return (
    <Modal
      header="Make Announcement"
      modalIsOpen={isOpen}
      closeModal={onClose}
      height="200px"
    >
      <div>
        <textarea
          className="mb-8 w-full rounded-lg bg-grey-4 py-3 px-4 text-sm text-white focus:outline-none"
          rows={4}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          placeholder="Enter Text..."
        />
        {error && <p className="text-center text-warning">{error}</p>}
        <Button
          text="Submit"
          variant="primary"
          rounded
          large
          className="!w-full"
          onClick={handleSend}
          isLoading={loading}
          disabled={loading || !value.trim().length}
        />
      </div>
    </Modal>
  );
};

export default AnnouncementModal;
