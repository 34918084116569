/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from '../../components/Buttons/Button/Button';
import client from '../../services/client';
import HornIcon from '../../assets/Icons/horn.svg';
import TrashCanIcon from '../../assets/Icons/trash-alt.svg';

const PlayByPlay = ({ event, matchId, currRound }) => {
  const [plays, setPlays] = useState([]);
  const [doneFetching, setDoneFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(null);

  const subscription = useRef(null);

  useEffect(() => {
    import('@rails/actioncable').then(({ createConsumer }) => {
      const cable = createConsumer(process.env.REACT_APP_WEBSOCKET_URL);

      const updateSubscription = cable.subscriptions.create(
        {
          channel: 'PlayByPlayChannel',
          match_id: matchId,
        },
        {
          received(data) {
            if (data.method_type === 'insert') {
              setPlays((p) => [data, ...p]);
            }
            if (data.method_type === 'remove') {
              setPlays((p) => p.filter((item) => item.id !== data.id));
            }
            if (data.method_type === 'update') {
              setPlays((p) =>
                p.map((item) => (item.id === data.id ? data : item)),
              );
            }
          },
        },
      );

      // @ts-ignore
      subscription.current = updateSubscription;
    });

    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, [matchId]);

  const { team1, team2 } = useMemo(
    () => ({
      team1: event.teams[0],
      team2: event.teams[1],
    }),
    [event.teams],
  );

  useEffect(() => {
    setPage(null);
    setPlays([]);
    setDoneFetching(false);
  }, [matchId]);

  useEffect(() => {
    setIsLoading(true);
    setDoneFetching(false);
    client
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/matches/${matchId}/play_by_plays?page_size=20${
          page ? `&start_play_id=${page}` : ''
        }`,
      )

      .then(({ data }) => {
        if (data.length < 20) {
          setDoneFetching(true);
        }
        if (!page) {
          setPlays(data);
        } else {
          setPlays((e) => [...e, ...data]);
        }
      })

      .finally(() => setIsLoading(false));
  }, [page, matchId]);

  const handleDelete = async (id) => {
    const prev = plays;
    setPlays((p) => p.filter((item) => item.id !== id));
    try {
      await client.delete(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/play_by_plays/${id}`,
      );
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
      setPlays(prev);
    }
  };

  const canDelete = (play) => {
    const index = plays
      .filter((p) => p.type === 'team' && p.round_number === currRound)
      .findIndex((p) => p.id === play.id);
    return index === 0;
  };
  return (
    <div className={classNames('flex-1 overflow-auto text-grey-2', {})}>
      <h3
        className={classNames(
          'sticky top-0 z-10 mb-2 flex items-center justify-between gap-4 bg-black pb-2 text-lg font-extrabold text-white sm:text-xl',
        )}
      >
        Play-by-Play
        <div className="flex items-center gap-2 whitespace-nowrap !text-lg font-bold">
          {team1 && (
            <span className="w-[40px] text-center">{team1.roster.code}</span>
          )}
          {team2 && (
            <span className="w-[40px] text-center">{team2.roster.code}</span>
          )}
          <span className="w-[40px] text-center" />
        </div>
      </h3>
      {plays.length > 0 ? (
        <div className="pb-8">
          {plays
            .sort((a, b) => b.id - a.id)
            .map((p, i) => (
              <div key={`Play_${p.id}`} className="mb-2">
                <div className="flex w-full items-center gap-2">
                  <div className="flex-1">
                    {p.type === 'status' && <StatusPlay play={p} />}
                    {p.type === 'announcement' && <AnnouncementPlay play={p} />}
                    {p.type === 'team' && (
                      <TeamPlay
                        play={p}
                        team1Id={team1?.roster.team_id}
                        team2Id={team2?.roster.team_id}
                      />
                    )}
                  </div>
                  {canDelete(p) ? (
                    <button
                      type="button"
                      className="w-[40px] !outline-none !ring-0 transition-opacity ease-in-out hover:opacity-75 focus-visible:!outline-blue"
                      onClick={() => handleDelete(p.id)}
                    >
                      <img src={TrashCanIcon} alt="delete" className="w-4" />
                    </button>
                  ) : (
                    <div className="w-[40px]" />
                  )}
                </div>
              </div>
            ))}
          {!doneFetching && plays.length > 0 && (
            <div
              className={classNames(
                'mt-8 flex items-center justify-center pb-8',
                {},
              )}
            >
              <Button
                variant="secondary"
                isLoading={isLoading}
                disabled={isLoading}
                text="Load More"
                onClick={() => setPage(plays[plays.length - 1].id)}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="pb-4">
          <p className="text-center text-grey-2">
            No data to display for this event yet
          </p>
        </div>
      )}
    </div>
  );
};

const StatusPlay = ({ play }) => (
  <div
    className={classNames(
      'flex items-stretch justify-between rounded-lg border border-grey-4 py-2 px-4',
    )}
  >
    <div className="flex w-12 items-center justify-start">
      {play.icon && (
        <img
          src={play.icon}
          className="h-[16px] w-[24px] object-contain object-left"
          alt={play.title}
        />
      )}
    </div>
    <div className="flex-1 text-center">
      <p className="text-xs uppercase">{play.title}</p>
      {play.description && <p className="text-[10px]">{play.description}</p>}
    </div>
    <div className="w-12" />
  </div>
);

const AnnouncementPlay = ({ play }) => (
  <div className={classNames('rounded-lg bg-grey-4 py-2 px-4')}>
    <div className="flex items-center justify-start gap-2">
      <img
        src={play.icon || HornIcon}
        className="h-[14px] w-[14px] object-contain object-left"
        alt={play.title}
      />
      <p className="text-xs font-bold uppercase leading-none text-white">
        {play.title}
      </p>
    </div>
    {play.description && (
      <p className="whitespace-wrap pt-2 text-xs">{play.description}</p>
    )}
  </div>
);

const TeamPlay = ({ play, team1Id, team2Id }) => {
  const renderContent = useCallback((t) => {
    if (t?.type === 'icon') {
      return (
        <img
          src={t.data.value}
          className="h-[20px] w-[20px] rounded-full object-cover"
          alt="Play"
        />
      );
    }
    if (t?.type === 'score') {
      return (
        <span
          style={{ color: t.data.won ? '#fff' : '#979797' }}
          className="text-sm font-bold"
        >
          {t.data.value}
        </span>
      );
    }
    return null;
  }, []);
  const team1Content = useMemo(() => {
    if (!team1Id) {
      return null;
    }
    const t = play.teams.find((team) => team.team_id === team1Id);
    return renderContent(t);
  }, [play.teams, team1Id, renderContent]);

  const team2Content = useMemo(() => {
    if (!team2Id) {
      return null;
    }
    const t = play.teams.find((team) => team.team_id === team2Id);
    return renderContent(t);
  }, [play.teams, team2Id, renderContent]);

  return (
    <div>
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center justify-start gap-2">
          <img
            src={play.icon}
            className="h-[16px] w-[16px] rounded-full object-cover object-left"
            alt={play.title}
          />
          <p className="text-xs leading-none">{play.title}</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex w-[40px] shrink-0 items-center justify-center text-center leading-none">
            {team1Content}
          </div>
          <div className="flex w-[40px] shrink-0 items-center justify-center text-center leading-none">
            {team2Content}
          </div>
        </div>
      </div>
      {play.description && <p className="pt-2 text-xs">{play.description}</p>}
    </div>
  );
};

export default PlayByPlay;
